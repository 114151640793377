var js={};

//删除元素（前提：元素存在）
js.remove=function (elem) {
    if (elem) {
        // 拿到待删除节点:
        var self = elem;
        // 拿到父节点:
        var parent = self.parentElement;
        // 删除:
        var removed = parent.removeChild(self);
        removed === self; // true
    }
}

//消息提示框（3秒自动消失）
js.tankuang=function (pWidth, content, second = 1500) {
    js.remove(document.getElementById("msg"));
    var html = '<div id="msg" style="position:fixed;top:50%;width:100%;height:30px;line-height:30px;margin-top:-15px;"><p style="background:#000;opacity:0.8;width:' + pWidth + 'px;color:#fff;text-align:center;padding:10px 10px;margin:0 auto;font-size:12px;border-radius:4px;">' + content + '</p></div>'
    document.body.innerHTML += html;
    // $("body").append(html);
    setTimeout(next, second);

    function next() {
        js.remove(document.getElementById("msg"));

    }
}
//读取服务器文件
js.loadFile=function (filePath) {
    var result = null;
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('GET', filePath, false);
    xmlhttp.send();
    if (xmlhttp.status == 200) {
        result = xmlhttp.responseText;
    }
    return result;
}

//通过class获取元素（getclass('class')[1]）
js.getClass=function (classname) {
    if (document.getElementsByClassName) {
        //使用现有方法
        return document.getElementsByClassName(classname);
    } else {
        //定义一个数组放classname
        var results = new Array();
        //获取所有节点元素
        var elem = document.getElementsByTagName('*');
        for (var i = 0; i < elem.length; i++) {
            if (elem[i].className.indexOf(classname) != -1) {
                results[results.length] = elem[i];
            }
        }
        return results;
    }
}

//隐藏元素
js.toggleHied=function (idname) {
    var t = document.getElementById(idname);
    t.style.display = 'none'; // 隐藏
}

//显示元素
js.toggleShow=function (idname) {
    var t = document.getElementById(idname);
    t.style.display = 'block'; // 以块级元素显示
}

//隐藏后不占空间
js.toggleDisplayStatus=function (idname) {
    var t = document.getElementById(idname);
    if (t.style.display === 'none') {
        t.style.display = 'block'; // 以块级元素显示
    } else {
        t.style.display = 'none'; // 隐藏
    }
}

//隐藏后不占空间
js.eletoggleDisplayStatus=function (t) {
    if (t.style.display === 'none') {
        t.style.display = 'block'; // 以块级元素显示
    } else {
        t.style.display = 'none'; // 隐藏
    }
}

//隐藏后占空间
js.hideAndShow=function (idname) {
    var t = document.getElementById(idname);
    if (t.style.visibility === 'hidden') {
        t.style.visibility = 'visible';
    } else {
        t.style.visibility = 'hidden';
    }
}

//去除首尾空格
js.Trim=function (str) {
    return str.replace(/(^\s*)|(\s*$)/g, '');
}

//JS操作cookies方法!

//写cookies
js.setCookie=function (name, value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString();
}

//读取cookies
js.getCookie=function (name) {
    var arr, reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    if (arr == document.cookie.match(reg))
        return unescape(arr[2]);
    else
        return null;
}

//删除cookies
js.delCookie=function (name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = js.getCookie(name);
    if (cval != null)
        document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
}
js.urlEncode=function(param, key, encode) {
    if (param == null) return '';
    var paramStr = '';
    var t = typeof (param);
    if (t == 'string' || t == 'number' || t == 'boolean') {
        paramStr += '&' + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
    } else {
        for (var i in param) {
            var k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
            paramStr += this.urlEncode(param[i], k, encode);
        }
    }
    return paramStr;
}
// console.log(urlEncode(obj));  
// //output: &name=tom&class.className=class1&classMates[0].name=lily  
// console.log(urlEncode(obj,'stu'));  
// //output: &stu.name=tom&stu.class.className=class1&stu.classMates[0].name=lily  


export default {
    js
}

