<template>
  <div class="insertorupdate">
    <!-- <Mask /> -->
    <div id="addCont" class="anchorFour" style="display: none;">
      <form id="add" class="add">
        <input class="WordID" type="hidden" v-model="item.WordID" />
        <input class="UserID" type="hidden" v-model="item.UserID" />
        <input class="IsDelete" type="hidden" v-model="item.IsDelete" />
        <div style="display: block;">
          <el-row type="flex" :gutter="6" justify="center">
            <el-col :xs="6" :sm="5" :md="4" :lg="3" :xl="2">
              <span style="width: 100% !important;"><a target="_blank" style="color:#ffff;" :href='"https://dict.cn/"+item.English'>English:</a></span>
            </el-col>
            <el-col :xs="16" :sm="13" :md="8" :lg="8" :xl="8">
              <input id="English"  style="width: 100% !important; " class="tx" v-model="item.English" />
            </el-col>
          </el-row>
        </div>
        <div style="display: block;">
          <el-row type="flex" :gutter="6" justify="center">
            <el-col :xs="6" :sm="5" :md="4" :lg="3" :xl="2">
              <span style="width: 100% !important;">中文:</span>
            </el-col>
            <el-col :xs="16" :sm="13" :md="8" :lg="8" :xl="8">
              <input id="Chinese" style="width: 100% !important; " class="tx" v-model="item.Chinese" />
            </el-col>
          </el-row>
        </div>
        <div style="display: block;">
          <el-row type="flex" :gutter="6" justify="center">
            <el-col :xs="6" :sm="5" :md="4" :lg="3" :xl="2">
              <span style="width: 100% !important;">词性:</span>
            </el-col>
            <el-col :xs="16" :sm="13" :md="8" :lg="8" :xl="8">
              <input
                id="PartOfSpeech"
                style="width: 100% !important; "
                class="tx"
                v-model="item.PartOfSpeech"
              />
            </el-col>
          </el-row>
        </div>
        <div style="display: block;">
          <el-row type="flex" :gutter="6" justify="center">
            <el-col :xs="6" :sm="5" :md="4" :lg="3" :xl="2">
              <span style="width: 100% !important;">类型:</span>
            </el-col>
            <el-col :xs="16" :sm="13" :md="8" :lg="8" :xl="8">
              <input id="Type" class="tx" style="width: 100% !important; " v-model="item.Type" />
            </el-col>
          </el-row>
        </div>
        <div style="display: block;">
          <el-row type="flex" :gutter="6" justify="center">
            <el-col :xs="6" :sm="5" :md="4" :lg="3" :xl="2">
              <span style="width: 100% !important;">备注:</span>
            </el-col>
            <el-col :xs="16" :sm="13" :md="8" :lg="8" :xl="8">
              <textarea
                id="Remark"
                style="width: 100% !important; height: 55px!important;"
                class="tx"
                v-model="item.Remark"
              ></textarea>
              <!-- <input type="textarea"  /> -->
            </el-col>
          </el-row>

          <br />
        </div>
        <!-- <div style="display: block;">
          <span>时间:</span>
          <input id="DateTime" class="tx" v-model="item.DateTime" />
          <br />
        </div>-->
        <div style="display: inline-block;">
          <input type="button" class="sub" id="addsub" @click="insertorupdate" value="提交" />
        </div>
      </form>

      <button class="close" onclick="toggleDisplayStatus('addCont');toggleDisplayStatus('mask');">关闭</button>
    </div>
  </div>
</template>

<script>
// import mask from "./mask.vue";

export default {
  name: "insertorupdate",
  data() {
    return {
      result: null,
      url_insertorupdate: this.$store.state.url + "einglish.php" ///insertorupdate"
    };
  },
  methods: {
    insertorupdate: function() {
      this.$axios
        .post(
          this.url_insertorupdate,
          "querytype=insertorupdate" + this.$js.urlEncode(this.item),
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded"
            }
          }
        )
        // .then(response => (this.result = response.data))
        .then(response => {
          this.result = response.data;
          // console.log(this.$js.urlEncode(this.item));
          console.log(this.result);
          console.log(this.item);
          console.log(this.result.success);
          if (this.result.success == true) {
            this.$notify({
              showClose: true,
              message: "新增或编辑成功！",
              type: "success"
            });
            // //打开模态框与遮罩
            this.$js.toggleDisplayStatus("addCont");
            this.$js.toggleDisplayStatus("mask");
            //刷新数据(通过Vuex调用父组件刷新事件)
            this.$store.state.indexQuery();
          } else {
            this.$notify({
              center: true,
              dangerouslyUseHTMLString: true,
              showClose: true,
              message: "新增或编辑失败！<br/><br/>" + this.result.data,
              type: "error"
            });
          }
        })
        .catch(function(error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  computed: {
    //计算属性的使用与store值绑定
    wordenglish:function(){
      return this.$store.state.wordenglish;
    },
    item: function() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getEnglish;
    }
  }
  // ,
  // components: {
  //   mask
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
