import Temp from '../Temp'
import App from '../App'

export default [{
    path: '/',
    component: Temp,
    children: [
        {
            path: '',
            component: App//r => require.ensure([], () => r(require('../App')), 'home')
        }, 
        {
            path: '/n',
            component: r => require.ensure([], () => r(require('../Note')), 'note')
        }, 
        {
            path: '/s',
            component: r => require.ensure([], () => r(require('../SingnalR')), 'singnalr')
        }, 
        {
            path: '/t',
            component: r => require.ensure([], () => r(require('../TempNote')), 'TempNote')
        }, 
        {
            path: '/t/:id',
            component: r => require.ensure([], () => r(require('../TempNote')), 'TempNote')
        }, 
        //{
        //     path: '/score',
        //     component: r => require.ensure([], () => r(require('../page/score')), 'score')
        // }
    ]
}]