//全局引用的组件
import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'
import routes from './router/router'
// import App from './App.vue'
import Temp from './Temp.vue'
import insertorupdate from './components/insertorupdate.vue'
import nav from './components/nav.vue'
import store from './vuex/store'
import js from './js/js.js'
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css'
import Cookies from 'js-cookie'
import "jquery";
import * as signalR from '@microsoft/signalr';
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min'
// import '@/css/css.css' /*引入公共样式*/
import {markdown} from 'markdown';
import  VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


//【全局组件】
//服务器地址
// const signal = new signalR.HubConnectionBuilder().withUrl('http://localhost:5001/chatHub', {}).build()
var signal = new signalR.HubConnectionBuilder().withUrl(store.state.signal_url+"/chatHub").build();


// window.drawer=false;

Vue.use(VueRouter)
const router = new VueRouter({
  routes
})

Vue.use(ElementUI)//全局使用ElementUI
Vue.use(VueQuillEditor)

Vue.prototype.$markdown = markdown
Vue.prototype.$js = js.js
Vue.prototype.$cookies = Cookies
Vue.prototype.$axios = axios //全局注册，使用方法为:this.$axios
Vue.prototype.$store = store //全局注册，使用方法为:this.$axios
Vue.prototype.$signalR = signal //全局注册，使用方法为:this.$axios

Vue.config.productionTip = false



/**
 * 获取当前时间
 * 格式YYYY-MM-DD
 */
Vue.prototype.getNowFormatDate = function () { // author: meizz
  var date=new Date();
  var dateArr={
          year:date.getFullYear(),
          Month:date.getMonth() + 1, // 月份
          Day :date.getDate(), // 日
          Hours: date.getHours(), // 小时
          Minutes:date.getMinutes(), // 分
          Seconds :  date.getSeconds()// 秒
  }
  for(var k in dateArr){
      if(dateArr[k]<10){
          dateArr[k]="0"+dateArr[k];
      }
  }
  var FullDate=dateArr.year+"-"+dateArr.Month+"-"+dateArr.Day+"T"+dateArr.Hours+":"+dateArr.Minutes+":"+dateArr.Seconds;
  return FullDate;
};


new Vue({
  router,
  render: h => h(Temp),
}).$mount('#app')

//新增编辑模态框
new Vue({
  render: h => h(insertorupdate),
}).$mount('#insertorupdate')

new Vue({
  render: h => h(nav),
}).$mount('#nav')

