<template>
  <div class="cont" id="app">
    <form id="querform">
      <span>English：</span>
      <input id="qEnglish" class="text" type="text" v-model="qlist.English" />
      <br />

      <span>Chinese：</span>
      <input id="qChinese" class="text" type="text" v-model="qlist.Chinese" />
      <br />
      <span>DateTime：</span>
      <el-date-picker
        class="selectedatetime"
        v-model="qlist.DateTime"
        align="right"
        type="date"
        placeholder="选择日期"
        :picker-options="pickerOptions"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
      ></el-date-picker>
      <br />

      <input type="button" id="query" @click="queryClick" value="提交" />
    </form>
    <!-- <br/> -->
    <div>总数：{{count}}
      <!-- <input type="text" v-model="count" /> -->
    </div>
    <!-- <br/> -->

    <!-- 分页 -->
    <ul id="ic" v-show="isShowIndex" class="ic">
      <template v-for="n in indexCount">
        <li
          @click="queryindex(n)"
          v-if="n == qlist.Index"
          v-bind:class="{'sct':'n == Index'}"
          value
          :key="n"
        >{{n}}</li>
        <li @click="queryindex(n)" v-else value :key="n">{{n}}</li>
      </template>
    </ul>
    <br />
    <button v-show="isLogin" class="animeadd" @click="insert">新增</button>
    <br />

    <div class="cent" v-for="(item,index) in list" :key="index">
      <input class="WordID" type="hidden" v-model="item.WordID" />
      <input class="UserID" type="hidden" v-model="item.UserID" />
      <input class="IsDelete" type="hidden" v-model="item.IsDelete" />
      <i>English:</i>
      <a target="_blank" :href='"https://dict.cn/"+item.English'>
        <b class="English">{{item.English}}</b>
      </a>
      <br />

      <div style="display: none;">
        <i>Chinese:</i>
        <b class="Chinese">{{item.Chinese}}</b>
        <br />
        <i>PartOfSpeech:</i>
        <b class="PartOfSpeech">{{item.PartOfSpeech}}</b>
        <br />
        <i>Type:</i>
        <b class="Type">{{item.Type}}</b>
        <br />
        <i>Remark:</i>
        <b class="Remark">{{item.Remark}}</b>
        <br />
        <i>CreateTime:</i>
        <b class="DateTime">{{item.CreateTime}}</b>
        <br />
      </div>
      <!-- <br/> -->
      <!-- <el-divider></el-divider> -->
      <button class="showDetails el-icon-caret-bottom" @click="showDetails">显示详情</button>
      <!-- <el-divider></el-divider> -->
      <br />
      <button v-show="isLogin" class="animeedit" @click="btnClick(index)">编辑</button>
      <button v-show="isLogin" class="animedelete" @click="delClick(index,item.WordID)">删除</button>
    </div>
    <div><p
          style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"
        >©2020 - 2020 晴朗的朝色 </p><a style="display:inline-block;text-decoration:none;height:20px;line-height:20px;" href="https://beian.miit.gov.cn/"> <p
          style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"
        >蜀ICP备20019676号-1 </p></a><a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51160302511619"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
      >
        <img src="./备案图标.png" style="float:left;" />
        <p
          style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"
        >川公网安备 51160302511619号</p>
      </a></div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      list: null,
      qlist: {
        WordID: "",
        UserID: "",
        Chinese: "",
        English: "",
        PartOfSpeech: "",
        Type: "",
        Remark: "",
        DateTime: "",
        CreateTime:"",
        IsDelete: "",
        Index: 1,
        Count: 10
      },
      url_query: this.$store.state.url + "einglish.php", // /query",
      url_delete: this.$store.state.url + "einglish.php", // /delete",
      count: 0,
      isLogin: false,
      isShowIndex: false,
      user: {
        username: "",
        userpsw: "",
        type: 0
      }
      // index:this.qlist.Index,
      // Count:this.qlist.Count,
    };
  },
  methods: {
    showDetails(event) {
      // alert("hello" + this.name + "!"); // `this` 在方法里指向当前 Vue 实例
      // var el = event.currentTarget; //复杂的click哈哈
      var el = event.target;
      if (el.innerText == "收起显示") {
        el.innerText = "显示详情";
      } else {
        el.innerText = "收起显示";
      }
      var classVal = el.getAttribute("class");
      if (el.classList.contains("el-icon-caret-bottom")) {
        classVal = classVal.replace(
          "el-icon-caret-bottom",
          "el-icon-caret-top"
        );
        el.setAttribute("class", classVal);
      } else {
        classVal = classVal.replace(
          "el-icon-caret-top",
          "el-icon-caret-bottom"
        );
        el.setAttribute("class", classVal);
      }

      // alert(el.innerText);
      var ele = el.previousElementSibling;
      // alert(ele.innerText);
      this.$js.eletoggleDisplayStatus(ele);
    },
    open() {
      this.$notify("这是一条消息提示");
    },

    openVn() {
      const h = this.$createElement;
      this.$notify({
        message: h("p", null, [
          h("span", null, "内容可以是 "),
          h("i", { style: "color: teal" }, "VNode")
        ])
      });
    },
    insert: function() {
      let tempenglish = {
        WordID: "",
        UserID: this.user.username,
        Chinese: "",
        English: "",
        PartOfSpeech: "",
        Type: "",
        Remark: "",
        DateTime: "",
        IsDelete: "0"
      };
      this.$store.dispatch("setEnglishName", tempenglish);
      this.$js.toggleDisplayStatus("addCont");
      this.$js.toggleDisplayStatus("mask");
      document.getElementById("English").focus();
    },
    //编辑动漫
    btnClick: function(index) {
      // alert(this.list[index].WordID);
      this.list[index].UserID = this.user.username;

      //组件间传递数据
      this.$store.dispatch("setEnglishName", this.list[index]);
      if (this.$store.state.english.English) {
        //打开模态框与遮罩
        this.$js.toggleDisplayStatus("addCont");
        this.$js.toggleDisplayStatus("mask");
      }
      document.getElementById("English").focus();
    },
    queryindex: function(n) {
      this.qlist.Index = n;
      this.queryClick();
    },
    queryClick: function() {
      console.log(this.qlist.DateTime);
      this.isShowIndex = true;
      this.$axios
        .post(
          this.url_query,
          "querytype=query" + this.$js.urlEncode(this.qlist),
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded"
            }
          }
        )
        // .then(response => (this.result = response.data))
        .then(response => {
          this.result = response.data;
          // console.log(this.$js.urlEncode(this.item,"querytype=query"));
          console.log(this.result);
          if (this.result.data.data) {
            console.log(this.result.data.data);
          }
          console.log(this.item);
          console.log(this.result.success);
          if (this.result.success == true) {
            this.list = this.result.data.data;
            // this.$message({
            //   dangerouslyUseHTMLString: true,
            //   showClose: true,
            //   message: "查询成功！<br/><br/>结果为：" + this.result.data.count,
            //   type: "success"
            // });
            this.count = this.result.data.count;
            // this.list.remove[index];
          } else {
            this.list = this.result.data.data;
            this.$notify({
              center: true,
              dangerouslyUseHTMLString: true,
              showClose: true,
              message: "查询失败！<br/><br/>结果为：" + this.result.data.count,
              type: "error"
            });
            this.count = this.result.data.count;
          }
        })
        .catch(function(error) {
          // 请求失败处理
          console.log(error);
        });
    },
    delClick: function(index, WordID) {
      var r = confirm("确认删除吗？");
      if (r == true) {
        // x = "您按了确认！";
      } else {
        // x = "您按了取消！";
        return;
      }
      this.$axios
        .post(
          this.url_delete,
          "querytype=delete" + this.$js.urlEncode(this.list[index]),
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded"
            }
          }
        )
        // .then(response => (this.result = response.data))
        .then(response => {
          this.result = response.data;
          // console.log(this.$js.urlEncode(this.item,"querytype=query"));
          console.log(this.result);
          console.log(this.item);
          console.log(this.result.success);
          if (this.result.success == true) {
            this.$notify({
              showClose: true,
              message: "删除成功！单词:" + this.list[index].English,
              type: "success"
            });
            //删除
            this.list = this.list.filter(t => t.WordID != WordID);
            // this.list.remove[index];
          } else {
            this.$notify({
              center: true,
              dangerouslyUseHTMLString: true,
              showClose: true,
              message: "删除失败！<br/><br/>" + this.result.data,
              type: "error"
            });
          }
        })
        .catch(function(error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  watch:{
    
  },
  computed: {
    //计算属性的使用与store值绑定
    indexCount: function() {
      // 通过vuex的getters方法来获取state里面的数据
      return Math.ceil(this.count / this.qlist.Count);
    }
  },
  mounted() {
    //验证用户是否登录（登录则显示前端样式、失败则清空登录Cookie）
    console.log(this.$cookies.get("login"));
    if (
      this.$cookies.get("login") == "true" &&
      this.$cookies.get("username") &&
      this.$cookies.get("userpsw") &&
      this.$cookies.get("type")
    ) {
      this.user.username = this.$cookies.get("username");
      this.user.userpsw = this.$cookies.get("userpsw");
      this.user.type = this.$cookies.get("type");
      this.isLogin = true;
      this.qlist.UserID = this.user.username;
    } else {
      this.$cookies.remove("login");
      this.$cookies.remove("username");
      this.$cookies.remove("userpsw");
      this.$cookies.remove("type");
      this.qlist.UserID = -1;
    }

    console.log("querytype=query&IsRand=true" + this.$js.urlEncode(this.qlist));
    this.$axios
      .post(
        this.url_query,
        "querytype=query&IsRand=true" + this.$js.urlEncode(this.qlist)
      )
      .then(response => {
        this.list = response.data.data.data;
        this.count = response.data.data.count;
      })
      .catch(function(error) {
        // 请求失败处理
        console.log(error);
      });
    this.$store.state.indexQuery = this.queryClick;
  },
  components: {
    // HelloWorld,
  }
};
</script>

<style>
@import "./css/css.css";
/*引入公共样式*/
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.selectedatetime {
  width: 60% !important;
}
.selectedatetime input {
  height: 19px;
  width: 100%;
  margin-left: 5px;
  border-radius: 5px;
  border: none;
}
.selectedatetime span {
  background-color: #fff !important;
  display: none !important;
}
.text {
  text-indent: 30px;
}
</style>
