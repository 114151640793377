import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
let store = new Vuex.Store({
    // 0. 严格模式
    // strict: true,
    // 1. state
    state: {
        //设置初始值-否则会因为使用时无该属性而冲突
        english: { "WordID": "", "UserID": "0", "Chinese": "", "English": "", "PartOfSpeech": "", "Type": "", "Remark": "", "DateTime": "", "IsDelete": "0" },
        // note: { "NoteID": "", "UserID": "0", "Name": "", "Content": "", "Remark": "", "DateTime": "", "IsDelete": "0" },
        url:"https://333123.xyz/",//"",//  "http://127.0.0.1:3000/",// 
        api_url: "https://333123.xyz:9002",
        signal_url: "https://333123.xyz:9001",
        isMask: false,
        isMode: false,
        drawer: false,//将其绑定到非组件按钮上（导航按钮上）
        ModeValue: "",
        indexQuery: function () { },
    },

    // // 2. getters
    getters: {
        // 参数列表state指的是state数据
        getEnglish(state) {
            return state.english;
        },
        // getNote(state) {
        //     return state.note;
        // },
    },
    // 3. actions
    // 通常跟api接口打交道
    actions: {
        // 设置城市信息
        // 参数列表：{commit, state}
        // state指的是state数据
        // commit调用mutations的方法 
        // name就是调用此方法时要传的参数
        setEnglishName({ commit }, name) {
            // 跟后台打交道
            // 调用mutaions里面的方法
            commit("setEnglish", name);
        },
        // updateUserInfo(name) {
        //     name.commit("setCity");
        // },
    },
    // 4. mutations
    mutations: {
        // state指的是state的数据
        // name传递过来的数据
        setEnglish(state, name) {
            state.english = name;//将传参设置给state的city
        }
    }
});

export default store;