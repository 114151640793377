<template>
  <!-- 导航页面 -->
  <!-- <el-button @click="drawer=true" type="primary" style="margin-left: 16px;">点我打开</el-button> -->
  <el-drawer title="页面导航" :visible.sync="drawer" :open="handleClose" :with-header="false">
    <br />
    <el-row type="flex" justify="center" :gutter="5">
      <el-col :span="22">
        <a href="/#/">
          <el-tag
            id="ele"
            effect="plain"
            style="width: 100% !important;color:#61BFAD;border-color: #61BFAD;"
          >单词</el-tag>
        </a>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center" :gutter="5">
      <el-col :span="22">
        <a href="/#/n">
          <el-tag
            effect="plain"
            style="width: 100% !important;color:#E54B4B;border-color: #E54B4B;"
          >笔记</el-tag>
        </a>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center" :gutter="5">
      <el-col :span="22">
        <a href="/#/s">
          <el-tag
            effect="plain"
            style="width: 100% !important;color:#61BFAD;border-color: #61BFAD;"
          >聊天</el-tag>
        </a>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center" :gutter="5">
      <el-col :span="22">
        <a href="/index.php">
          <el-tag
            effect="plain"
            style="width: 100% !important;color:#E54B4B;border-color: #E54B4B;"
          >动漫</el-tag>
        </a>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center" :gutter="5">
      <el-col :span="22">
        <a href="/#/t">
          <el-tag
            effect="plain"
            style="width: 100% !important;color:#61BFAD;border-color: #61BFAD;"
          >临时文本</el-tag>
        </a>
      </el-col>
    </el-row>

        <el-row type="flex" justify="center" :gutter="5">
      <el-col :span="22">
        <a href="/html/file.html">
          <el-tag
            effect="plain"
            style="width: 100% !important;color:#E54B4B;border-color: #E54B4B;"
          >文件</el-tag>
        </a>
      </el-col>
    </el-row>

  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: this.$store.state.drawer
    };
  },
  methods: {
    handleClose() {
      let ele = document.getElementById("ele");
      ele.focus();
    }
  },

  computed: {
    //计算属性的使用与store值绑定
    notedrawer: function() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.state.drawer;
    }
  },
  watch: {
    //监听当前值变化（若变化则更新vuex中的值）
    drawer: function(val) {
      this.$store.state.drawer = val;
    },
    //监听vuex中值变化
    notedrawer: function(val) {
      this.drawer = val;
    }
  }
};
</script>

<style>
.el-tag {
  height: 39px;
  line-height: 39px;
  text-align: center;
}
.el-row {
  margin-bottom: 5px;
}
</style>
